import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;

const FaqAccordionSection = () => {
  const { i18n } = useTranslation();
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}/api/faqs`)
      .then(response => {
        setFaqs(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const [openAccordionId, setOpenAccordionId] = useState(null);

  const handleAccordionBtn = (itemId) => {
    setOpenAccordionId((prevState) => (prevState === itemId ? null : itemId));
  };

  return (
    <div className="faq-accordion-area">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {faqs.slice(0, 4).map((faq) => (
              <div
                className={`fz-single-accordion-item ${
                  openAccordionId === faq.id ? 'open' : ''
                }`}
                key={faq.id}
              >
                <div
                  className="fz-single-accordion-item__header"
                  role="button"
                  onClick={() => handleAccordionBtn(faq.id)}
                >
                  <h3 className="fz-single-accordion-item__title">
                    {i18n.language === 'en' ? faq.question_en : faq.question_ar}
                  </h3>
                  <i
                    className={`fa-regular ${
                      openAccordionId === faq.id ? 'fa-minus' : 'fa-plus'
                    }`}
                  ></i>
                </div>

                <p className="fz-single-accordion-item__body">
                  {i18n.language === 'en' ? faq.answer_en : faq.answer_ar}
                </p>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {faqs.slice(4).map((faq) => (
              <div
                className={`fz-single-accordion-item ${
                  openAccordionId === faq.id ? 'open' : ''
                }`}
                key={faq.id}
              >
                <div
                  className="fz-single-accordion-item__header"
                  role="button"
                  onClick={() => handleAccordionBtn(faq.id)}
                >
                  <h3 className="fz-single-accordion-item__title">
                    {i18n.language === 'en' ? faq.question_en : faq.question_ar}
                  </h3>
                  <i
                    className={`fa-regular ${
                      openAccordionId === faq.id ? 'fa-minus' : 'fa-plus'
                    }`}
                  ></i>
                </div>

                <p className="fz-single-accordion-item__body">
                  {i18n.language === 'en' ? faq.answer_en : faq.answer_ar}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAccordionSection;