import React, { useState, useEffect } from 'react';
import CommentFormSection from '../forms/CommentFormSection';
import { Link, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;


const PageDetailSection = () => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug_en } = useParams();
  
    useEffect(() => {
      fetchPageDetails(slug_en);
    }, [slug_en]);
  
    const fetchPageDetails = (slug_en) => {
      fetch(`${apiUrl}/api/page/${slug_en}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch page details');
          }
        })
        .then(data => {
          // Remove HTML tags from description_en
          // const plainTextDescription = data.description_en.replace(/<[^>]+>/g, '');
          // data.description_en = plainTextDescription;
          setPage(data);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
  
    };
  
  
    
    return (
    <div className="container">
        <main className="blog-page-content">
            <div className="pages-container fz-blog-details-container">
                <div className="blogs">
                    <div className="fz-single-blog fz-inner-page-blog fz-blog-details">
                        <div className="fz-single-blog__img fz-blog-details__img">
                            <img src={`${apiUrl}/uploads/${page.image}`} alt={i18n.language === 'en' ? page.title_en : page.title_ar} />

                            <div className="fz-blog-details__heading">
                                <h3 className="fz-single-blog__title fz-blog-details__title">{i18n.language === 'en' ? page.title_en : page.title_ar}</h3>

                            </div>
                        </div>

                        <div className="fz-single-blog__txt fz-blog-details__txt">
                            <div className="fz-single-blog__desc fz-blog-details__descr">
                            {i18n.language === 'en' ? page.description_en : page.description_ar}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
  )
}

export default PageDetailSection