import React, { useContext, useState,useEffect } from 'react'
import { FarzaaContext } from '../../context/FarzaaContext'
import { Link , useParams} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;
const RelatedProductSection = () => {
    const [t, i18n] = useTranslation();
    const [products, setProducts] = useState([]);
    const { slug_en } = useParams();


    useEffect(() => {
        const fetchProduct = async () => {
          try {
            const response = await axios.get(`${apiUrl}/api/product/${slug_en}`);
            const currentProduct = response.data;
            const brandId = currentProduct.brand_id;
    
            const responseRelated = await axios.get(`${apiUrl}/api/products/search?brand_id=${brandId}`);
            const relatedProducts = responseRelated.data.slice(0, 4); // Get only the first 4 products
            setProducts(relatedProducts);
          } catch (error) {
            console.error(error);
          }
        };
        fetchProduct();
      }, [slug_en]);

    const {
        paginatedProducts,
        addToCart,
        addToWishlist,
        tryOn,
    } = useContext(FarzaaContext)
  return (
    <section className="related-product-section">
        <div className="container">
            <h2 className="related-product__title">{t('Related Products')}</h2>
            <div className="row gy-sm-4 g-3 justify-content-center">
                {products.map((product) => (
                <div className="col-lg-3 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
                <div className="fz-5-single-product">
                  <div className="fz-5-single-product-img">
                    <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                    <div className="fz-5-single-product-actions">
                      <a 
                        role="button" 
                        className="fz-add-to-wishlist"
                        onClick={() => addToWishlist(product)}
                      >
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      <a
                        role="button"
                        className="fz-add-to-cart-btn" 
                        onClick={() => addToCart(product)}
                      >
                        <i className="fa-regular fa-cart-shopping"></i>
                      </a>
                    </div>
                  </div>
                  <div className="fz-5-single-product-txt">
                    <h3 className="fz-5-single-product-title">
                      <Link to={`/product/${product.slug_en}`}>
                        {i18n.language === 'en' ? product.title_en : product.title_ar}
                      </Link>
                    </h3>
                    <p className="fz-5-single-product-price">{t("SR")} {product.selling_price}</p>
                  </div>
                </div>
              </div>
                ))}
                
            </div>
        </div>
    </section>
  )
}

export default RelatedProductSection