import React, { useState,useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;

const FrameBrandFilter = () => {

  const [ t, i18n ] = useTranslation();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState({});


  useEffect(() => {
    // Fetch all products
    axios.get(`${apiUrl}/api/products`)
      .then(productResponse => {
        setProducts(productResponse.data);

        // Fetch all brands
        axios.get(`${apiUrl}/api/brands`)
          .then(brandResponse => {
            const allbrands = brandResponse.data.reduce((acc, brand) => {
              acc[brand.id] = {
                id: brand.id,
                title: i18n.language === 'en' ? brand.name_en : brand.name_ar,
              };
         
              return acc;
            }, {});

            setBrands(allbrands);

            // Filter brands based on products
            const productBrandIds = new Set(productResponse.data.data.map(product => product.brand_id));
            const availableBrands = Object.values(allbrands).filter(brand => productBrandIds.has(brand.id));
            setFilteredBrands(availableBrands);
          
          })
          .catch(error => {
            console.error("Error fetching brands:", error);
          });
      })
      .catch(error => {
        console.error("Error fetching products:", error);
      });
  }, [i18n.language]);

  const handleBrandClick = (brandId) => {
    setSelectedBrandId(brandId);
    fetchProductsByBrand(brandId);
    navigate(`/shop/search?brand_id=${brandId}`);
  };

  const fetchProductsByBrand = (brandId) => {
    axios.get(`${apiUrl}/api/products/search?brand_id=${brandId}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error("Error fetching products by brand:", error);
      });
  };

  const handleBrandChange = (brandId) => {
    setSelectedBrands((prevState) => ({
      ...prevState,
      [brandId]: !prevState[brandId],
    }));
  };


  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Brands')}</h3>
      <ul className="product-categories">

      {filteredBrands.map((brand) => (
        <li className="cat-item" key={brand.id}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={brand.id}
              checked={selectedBrands[brand.id]}
              onChange={() => handleBrandClick(brand.id)}
            />
                        <label className="form-check-label" htmlFor={brand.id}>
                        {brand.title}
                        </label>
          </div>
        </li>
))}
                  

      </ul>
    </div>
  );
};

export default FrameBrandFilter;
