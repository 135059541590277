// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
// import axios from "axios";
// import { useTranslation } from 'react-i18next';
// const apiUrl = import.meta.env.VITE_API_URL;

// const BannerSection5 = () => {

//   const [banners, setBanners] = useState([]);
//   const { t, i18n } = useTranslation(); 


// useEffect(() => {
//   axios.get(`${apiUrl}/api/banners`)
//     .then(response => {
//       console.log(response.data); // Log the response data
//       setBanners(response.data);
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }, [banners]);
//   return (
//     <section className="fz-5-banner-section fz-5-banner-slider">


// <Swiper autoplay={true} loop={true} modules={[Autoplay]} effect="fade">
//   {banners.map((banner, index) => {
//     console.log(`Banner ${index}:`, banner); // Log each banner for inspection
//     return (
//       <SwiperSlide key={index} style={{ backgroundImage: `url(${apiUrl}/uploads/${banner.image})` }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="fz-5-banner-txt">
//                 <h3>{i18n.language === 'en' ? banner.title_en : banner.title_ar}</h3>
//                 <h1>{i18n.language === 'en' ? banner.subtitle_en : banner.subtitle_ar}</h1>
//                 <Link to={banner.link} className="fz-5-def-btn">
//                   {t("shop_now")}
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </SwiperSlide>
//     );
//   })}
// </Swiper>    </section>
//   );
// };

// export default BannerSection5;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'; // Import Carousel from react-bootstrap

const apiUrl = import.meta.env.VITE_API_URL;

const BannerSection5 = () => {
  const [banners, setBanners] = useState([]);
  const { t, i18n } = useTranslation(); 
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    axios.get(`${apiUrl}/api/banners`)
      .then(response => {
        console.log(response.data); // Log the response data
        setBanners(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []); // Run once on mount

  // Check if banners are loaded
  if (banners.length === 0) {
    return <div>Loading...</div>; // Show loading state
  }

  return (
    <section className="fz-5-banner-section fz-5-banner-slider">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {banners.map((banner, idx) => {
          console.log(`Banner ${idx}:`, banner); // Log each banner for inspection
          return (
            <Carousel.Item key={idx} style={{ backgroundImage: `url(${apiUrl}/uploads/${banner.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="text-center text-white">
                  <h3>{i18n.language === 'en' ? banner.title_en : banner.title_ar}</h3>
                  <h1>{i18n.language === 'en' ? banner.subtitle_en : banner.subtitle_ar}</h1>
                  <br />
                  <Link to={banner.link} className="fz-5-def-btn">
                    {t("shop_now")}
                  </Link>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default BannerSection5;