
import React, { useContext, useState, useEffect } from 'react';
import { FarzaaContext } from '../../context/FarzaaContext';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../context/StoreContext';

import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;



const ProductContainer2 = () => {
  const [t, i18n] = useTranslation();
  const { pathname } = useLocation();
  const { slug } = useParams();
  const [products, setCategoryProducts] = useState([]);
  const [isCategory, setIsCategory] = useState(true);
  const [isSubcategory, setIsSubcategory] = useState(true);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
      const fetchProduct = async () => {
          try {
              const url = `${apiUrl}/api${pathname}`;
              const response = await axios.get(url);
              const { data, ...pagination } = response.data;
              setCategoryProducts(data);
              setPagination(pagination);
          } catch (error) {
              console.error(error);
          }
      };
      fetchProduct();
  }, [slug, isCategory, isSubcategory]); // Merged fetch logic

  const { isListView } = useContext(FarzaaContext);
  const { addToWishlist, addToCart } = useContext(StoreContext);

  const handleAddToCart = (product) => {
      console.log('Adding to cart:', product); // Log the product being added
      addToCart(product);
  };

  return (
      <div className={`fz-inner-products-container ${isListView ? 'list-view-on' : ''}`}>
          <div className="row justify-content-center">
              {products.length === 0 ? (
                  <div className='no-product-area'>
                      <h3 className='no-product-text'>{t('No Products Available')}</h3>
                      <p className='no-product-desc'>{t("We're sorry. We cannot find any matches for your search term.")}</p>
                  </div>
              ) : (
                  products.map((product) => (
                      <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
                          <div className="fz-5-single-product">
                              <div className="fz-5-single-product-img">
                                  <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                                  <div className="fz-5-single-product-actions">
                                      <a
                                          role="button"
                                          className="fz-add-to-wishlist"
                                          onClick={() => addToWishlist(product)}
                                      >
                                          <i className="fa-regular fa-heart"></i>
                                      </a>
                                      <a
                                          role="button"
                                          className="fz-add-to-cart-btn"
                                          onClick={() => handleAddToCart(product)} // Use the handler
                                      >
                                          <i className="fa-regular fa-cart-shopping"></i>
                                      </a>
                                  </div>
                              </div>
                              <div className="fz-5-single-product-txt">
                                  <h3 className="fz-5-single-product-title">
                                      <Link to={`/product/${product.slug_en}`}>
                                          {i18n.language === 'en' ? product.title_en : product.title_ar}
                                      </Link>
                                  </h3>
                                  <p className="fz-5-single-product-price">{t("SR")} {product.selling_price}</p>
                              </div>
                          </div>
                      </div>
                  ))
              )}
          </div>
          <div className="p-2">
              {pagination.links && (
                  <nav aria-label="Pagination">
                      <ul className="pagination justify-content-center">
                          {pagination.links.map((link, index) => (
                              <li key={index} className={link.active ? 'page-item active' : 'page-item'}>
                                  <a
                                      style={{ color: 'black' }}
                                      className="page-link"
                                      href={link.url}
                                      onClick={(e) => {
                                          e.preventDefault();
                                          const pageNumber = link.label === 'Next' ? pagination.current_page + 1 : link.label === 'Previous' ? pagination.current_page - 1 : link.label;
                                          fetchProductsByPage(pageNumber); // Make sure to implement this function
                                      }}
                                  >
                                      {link.label === '&laquo; Previous' ? 'Previous' : link.label === 'Next &raquo;' ? 'Next' : link.label}
                                  </a>
                              </li>
                          ))}
                      </ul>
                  </nav>
              )}
          </div>
      </div>
  );
};

export default ProductContainer2;









