import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { StoreContext } from '../../context/StoreContext'; 
const apiUrl = import.meta.env.VITE_API_URL;




const AccesoriesSection = () => {
  const {addToWishlist, addToCart} = useContext(StoreContext);

  const [ t, i18n ] = useTranslation();
  const { slug } = useParams();
  const [products, setCategoryProducts] = useState([]);
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const url = `${apiUrl}/api/products/category/accessories`;
        const response = await axios.get(url);
        const data = response.data.data; // Access the data property

        // Sort products by created_at or another relevant timestamp if available, and get the last 3
        const sortedProducts = data.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
        const latestThreeProducts = sortedProducts.slice(-3);
        setCategoryProducts(latestThreeProducts);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
  }, [slug]);


  return (
    <section className="fz-5-product pt-120 pb-120">
      <div className="container">
        <div className="row g-xl-4 g-lg-3 g-4">
          <div className="col-12">
            <div className="row g-xl-4 g-lg-3 g-2">
              {/* /////start loop products////// */}
              {products.map((product) => (
              <div className="col-lg-3 col-md-4 col-6 order-lg-1 order-2">
                <div className="fz-5-single-product">
                  <div className="fz-5-single-product-img">
                    <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                    <div className="fz-5-single-product-actions">
                      <a 
                      role="button" 
                      className="fz-add-to-wishlist"
                      onClick={() => addToWishlist(product)}
                      >
                        <i className="fa-regular fa-heart"></i>
                      </a>
                      {/* <a role="button" className="fz-quick-view">
                        <i className="fa-regular fa-eye"></i>
                      </a> */}
                      {/* <a role="button" className="fz-compare">
                        <i className="fa-regular fa-repeat"></i>
                      </a> */}
                      <a
                       role="button"
                       className="fz-add-to-cart-btn" 
                       onClick={() => addToCart(product)}
                       >
                        <i className="fa-regular fa-cart-shopping"></i>
                      </a>
                    </div>
                  </div>
                  <div className="fz-5-single-product-txt">
                    <h3 className="fz-5-single-product-title">
                      <Link to={`/product/${product.slug_en}`}>{i18n.language === 'en' ? product.title_en : product.title_ar}</Link>
                    </h3>
                    <p className="fz-5-single-product-price">{t("SR")} {product.selling_price}</p>
                  </div>
                </div>
              </div>
              ))}

{/* /////end loop products////// */}

              <div className="col-lg-3 col-md-4 col-6 order-lg-4 order-1">
                <div className="fz-5-product-title-box">
                  <h3>{t('Accesories')}</h3>
                  <Link to="/products/category/accessories" className="fz-5-def-btn-2">
                    {t('View Products')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccesoriesSection;
