import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import { AuthContext } from '../authentication/AuthContext';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,   
  MDBCardBody,
  MDBCardImage,
} from 'mdb-react-ui-kit';
const apiUrl = import.meta.env.VITE_API_URL;


export default function MyAccountSection() {
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    image: '',
    phone: '',
  });

  const [addressData, setAddressData] = useState({
    address: '',
    country: '',
    state: '',
    city: '',
    street: '',
    appartment: '',
    zip: '',
  });

  const [lastOrder, setLastOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);


  const [passwordModal, setPasswordModal] = useState(false);
  const statusMapping = {
    0: 'Pending',
    1: 'Confirmed',
    2: 'Refused',
    3: 'Pickup',
    4: 'On Way',
    5: 'Completed'
  };
  

useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        toast.error(t('Failed to fetch user information'));
      }
    };
  
    if (token) {
      fetchUserData();
    }
  }, [token, t]);

  useEffect(() => {
    const fetchLastOrder = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLastOrder(response.data);
        console.log(response.data);
      } catch (error) {
      console.error(error);
      toast.error(t('Failed to fetch last order'));
}
    };
  
    if (token) {
      fetchLastOrder();
    }
  }, [token, t]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/addresses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAddressData(response.data);
      } catch (error) {
      console.error(error);
      toast.error(t('Failed to fetch Addresses'));
}
    };
  
    if (token) {
      fetchAddresses();
    }
  }, [token, t]);



  const handleDefaultAddressChange = (addressId) => {
    axios.post(`${apiUrl}/api/update-default/${addressId}`, {
      set_default: 1,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      toast.success(t('Default address updated successfully'));
      // Update the state with the new default address
      setAddressData(addressData.map((address) => {
        if (address.id === addressId) {
          address.set_default = 1;
        } else {
          address.set_default = 0;
        }
        return address;
      }));
    })
    .catch((error) => {
      console.error(error);
      toast.error(t('Failed to update default address'));
    });
  };
  
  const handleEditAddress = (addressId) => {
    axios.get(`${apiUrl}/api/address/${addressId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const addressData = response.data;
        setAddressData(addressData);
        setShowModalAddress(true);
      })
      .catch((error) => {
        console.error(error);
        toast.error(t('Failed to fetch address data'));
      });
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };


  const handleInputChangeAddress = (e) => {
    const { name, value } = e.target;
    setAddressData((prevState) => ({
      ...prevState,
      [name]: value || '', // add this check
    }));
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserData({ ...userData, image: file });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append other form fields in UserData 
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('phone', userData.phone);
    // Append image file
    if (userData.image) {
      formData.append('image', userData.image);
    }

    axios.post(`${apiUrl}/api/edit-user/${userData.id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      toast.success(t('User information updated successfully'));
      setUserData(response.data);
      setShowModal(false);
    })
    .catch(error => {
      console.error(error);
      toast.error(t('Failed to update user information'));
    });
  };

  // const handleFormSubmitAddress = (e) => {
  //   e.preventDefault();
  
  //   const formDataAdress = new FormData();
  
  //   Object.keys(addressData).forEach((key) => {
  //     formDataAdress.append(key, addressData[key]);
  //   });
  
  //   axios.post(`${apiUrl}/api/add-address`, formDataAdress, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((response) => {
  //       toast.success(t('Address Adding successfully'));
  //       setAddressData(response.data);
  //       setShowModalAddress(false); // hide the modal after successful creation
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error(t('Failed to adding address information'));
  //     });
  // };

  const handleFormSubmitAddress = (e) => {
    e.preventDefault();
  
    const formDataAdress = new FormData();
  
    Object.keys(addressData).forEach((key) => {
      formDataAdress.append(key, addressData[key] || '');
    });
  
    console.log('Address data:', addressData);
  
    const url = addressData.id
      ? `${apiUrl}/api/edit-address/${addressData.id}`
      : `${apiUrl}/api/add-address`;
  
    axios.post(url, formDataAdress, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const message = addressData.id ? t('Address updated successfully') : t('Address added successfully');
        toast.success(message);
        setAddressData(response.data);
        setShowModalAddress(false);
      })
      .catch((error) => {
        console.error(error);
        const message = addressData.id ? t('Failed to update address') : t('Failed to add address');
        toast.error(message);
      });
  };

  const handlePasswordChange = async () => {
    const currentPassword = document.getElementsByName('current_password')[0].value;
    const newPassword = document.getElementsByName('new_password')[0].value;
    const confirmNewPassword = document.getElementsByName('confirm_new_password')[0].value;
  
    if (newPassword !== confirmNewPassword) {
      toast.error(t('New password and confirm new password do not match'));
      return;
    }
  
    try {
      const response = await axios.post(`${apiUrl}/api/reset-password`, {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmNewPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      toast.success(t('Password changed successfully'));
      setPasswordModal(false);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      toast.error(t('Failed to change password'));
    }
  };


  
  const handleRemoveAddress = (addressId) => {
    axios.post(`${apiUrl}/api/delete-address/${addressId}`, {}, 
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      toast.success(t('Address removed successfully'));
    })
    .catch((error) => {
      console.error(error);
      toast.error(t('Failed to remove address'));
    });
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleClosePasswordModal = () => {
    setPasswordModal(false);
  };
  
  

  return (
    <section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                src={userData.image ? `${apiUrl}/uploads/${userData.image}` : "https://static.vecteezy.com/system/resources/previews/018/765/757/original/user-profile-icon-in-flat-style-member-avatar-illustration-on-isolated-background-human-permission-sign-business-concept-vector.jpg"}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
                <p className="text-muted mb-1">{userData.name}</p>
                <p className="text-muted mb-4">{userData.email}</p>
                <div className="row">
                <div className="d-flex justify-content-center mb-2">
                  <Button variant="dark" onClick={() => setShowModal(true)}>
                    {t('Edit Profile')}
                  </Button>
                </div>
                <div className="d-flex justify-content-center mb-2">
                  <Button variant="dark" onClick={() => setPasswordModal(true)}>
                  {t('Change Password')}
                  </Button>
                </div>
                </div>
              </MDBCardBody>
            </MDBCard>

            {/* Last Order */}
            {lastOrder && lastOrder[0] && (
              <MDBCard className="mb-4 mb-lg-0">
                <MDBCardBody className="p-0">
                  <Card>
                    <Card.Header as="h3">{t('Last Order')}</Card.Header>
                    <Card.Body>
                      <p>{t('Order number')}: {lastOrder[0].order_no}</p>
                      <p>{t('Order Date')}: {new Date(lastOrder[0].ordered_at).toLocaleDateString()}</p>
                      <p>{t('Total')}: {lastOrder[0].total_price}</p>
                      <p>{t('Status')}: {statusMapping[lastOrder[0].status]}</p>

                      {/* <Link to={`/order/${lastOrder[0].id}`} className="btn btn-primary">
                        {t('View Order')}
                      </Link> */}
                    </Card.Body>
                  </Card>
                </MDBCardBody>
              </MDBCard>
            )}


          </MDBCol>

          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                {/* User Information */}
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>{t('Full Name')}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{userData.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>{t('Email')}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{userData.email}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>{t('Phone')}</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{userData.phone}</MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <div className="d-flex justify-content-center mb-2">
             <h2 className="text-center ">{t('Addresses')}</h2>

              <Button variant="dark" className="p-2" onClick={() => setShowModalAddress(true)}>{t('Add Address')}</Button>


             </div>
{/* //// Address Information Start */}
<MDBCol lg="5">
    {Array.isArray(addressData) && addressData.map((address, index) => (
 
    <MDBCard className="mb-4" key={index}>
      <MDBCardBody>
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('Address')}</MDBCardText>

          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.address}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('Country')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.country}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('State')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.state}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('City')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.city}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('Street')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.street}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('Apartment no')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.appartment}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol sm="3">
            <MDBCardText>{t('Zip code')}</MDBCardText>
          </MDBCol>
          <MDBCol sm="9">
            <MDBCardText className="text-muted">{address.zip}</MDBCardText>
          </MDBCol>
        </MDBRow>
        <hr />

        <MDBRow className="justify-content-center">
  <MDBCol sm="3">
    {address.set_default == 0 && (
      <>
        <Form.Check
          type="radio"
          name="set_default"
          value={address.id}
          label={t('Default Address')}
          onChange={() => handleDefaultAddressChange(address.id)}
        />

      </>
    )}
<div className='p-1 row d-flex'>
  <div className="btn-group">
    <Button variant="dark" className="me-2 rounded" onClick={() => handleRemoveAddress(address.id)}>
      {t('Remove Address')}
    </Button>
    <Button variant="dark" className="me-2 rounded" onClick={() => handleEditAddress(address.id)}>
      {t('Edit Address')}
    </Button>
  </div>
</div>
  </MDBCol>
</MDBRow>
      </MDBCardBody>
    </MDBCard>
  ))}
</MDBCol>
{/* //// Address Information End */}
        </MDBRow>
      </MDBContainer>

      {/* Modal for Editing Profile Start */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Profile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>   
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>{t('Full Name')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>{t('Email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formCountry" className="mb-3">
              <Form.Label>{t('Phone')}</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={userData.phone}
                onChange={handleInputChange}
              />
            </Form.Group>


            <Form.Group controlId="formImage" className="mb-3">
              <Form.Label>{t('Image')}</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleImageChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              {t('Save Changes')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
            {/* Modal for Editing Profile end */}

            {/* Modal for Add Address Start */}
      <Modal show={showModalAddress} onHide={() => setShowModalAddress(false)}>
        

        <Modal.Header closeButton>
          <Modal.Title>{t('Add Address')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmitAddress}>
          <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>{t('Address')}</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={addressData.address}
              onChange={handleInputChangeAddress}
              />
            </Form.Group>

            <Form.Group controlId="formCountry" className="mb-3">
              <Form.Label>{t('Country')}</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={addressData.country}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>

            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>{t('State')}</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={addressData.state}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>
            <Form.Group controlId="formCity" className="mb-3">
              <Form.Label>{t('City')}</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={addressData.city}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>
            <Form.Group controlId="formStreet" className="mb-3">
              <Form.Label>{t('Street')}</Form.Label>
              <Form.Control
                type="text"
                name="street"
                value={addressData.street}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>

            <Form.Group controlId="formAppartment" className="mb-3">
              <Form.Label>{t('Apartment no')}</Form.Label>
              <Form.Control
                type="text"
                name="appartment"
                value={addressData.appartment}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>

            <Form.Group controlId="formZip" className="mb-3">
              <Form.Label>{t('Zip code')}</Form.Label>
              <Form.Control
                type="text"
                name="zip"
                value={addressData.zip}
                onChange={handleInputChangeAddress}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className='btn btn-dark p-2 m-2'>
              {t('Save Changes')}
            </Button>
            <Button onClick={() => setShowModalAddress(false)} className='btn btn-secondary p-2 m-2'>
  {t('Cancel')}
</Button>
          </Form>
        </Modal.Body>
      </Modal>
            {/* Modal for Add Address end */}

      {/* Modal for Changing Password */}
<Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title> </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formCurrentPassword" className="mb-3">
        <Form.Label>{t('Current Password')}</Form.Label>
        <Form.Control
          type="password"
          name="current_password"
          placeholder={t('Enter Current Password')}
        />
      </Form.Group>

      <Form.Group controlId="formNewPassword" className="mb-3">
        <Form.Label>{t('New Password')}</Form.Label>
        <Form.Control
          type="password"
          name="new_password"
          placeholder={t('Enter New Password')}
        />
      </Form.Group>

      <Form.Group controlId="formConfirmNewPassword" className="mb-3">
        <Form.Label>{t('Confirm Password')}</Form.Label>
        <Form.Control
          type="password"
          name="confirm_new_password"
          placeholder={t('Confirm new password')}
        />
      </Form.Group>
      <Button variant="primary" onClick={handlePasswordChange}>
         {t('Change Password')}
      </Button>
    </Form>
  </Modal.Body>
</Modal>
    </section>
  );
}
