import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;
const FrameShapeFilter = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const [shapes, setShapes] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredShapes, setFilteredShapes] = useState([]);
  const [selectedShapeId, setSelectedShapeId] = useState(null);

  useEffect(() => {
    // Fetch all products
    axios.get(`${apiUrl}/api/products`)
      .then(productResponse => {
        setProducts(productResponse.data);

        // Fetch all shapes
        axios.get(`${apiUrl}/api/shapes`)
          .then(shapeResponse => {
            const allShapes = shapeResponse.data.reduce((acc, shape) => {
              acc[shape.id] = {
                id: shape.id,
                title: i18n.language === 'en' ? shape.title_en : shape.title_ar,
                image: shape.image
              };
              return acc;
            }, {});

            setShapes(allShapes);

            // Filter shapes based on products
            const productShapeIds = new Set(productResponse.data.data.map(product => product.shape_id));
          
            const availableShapes = Object.values(allShapes).filter(shape => productShapeIds.has(shape.id));
         
            setFilteredShapes(availableShapes);
          })
          .catch(error => {
            console.error("Error fetching shapes:", error);
          });
      })
      .catch(error => {
        console.error("Error fetching products:", error);
      });
  }, [i18n.language]);

  const handleShapeClick = (shapeId) => {
    setSelectedShapeId(shapeId);
    fetchProductsByShape(shapeId);
    navigate(`/shop/search?shape_id=${shapeId}`);
  };

  const fetchProductsByShape = (shapeId) => {
    axios.get(`${apiUrl}/api/products/search?shape_id=${shapeId}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error("Error fetching products by shape:", error);
      });
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Frame Shape')}</h3>
      <ul className="frame-shape">
      <li className="shape-item" >
            <a href="/shop">
              {/* <div className="fz-shape-img">
                <img src='' alt="image" />
              </div> */}
              <div className="fz-shape-txt">
                <span>{t('All')}</span>
              </div>
            </a>
          </li>
        {filteredShapes.map((shape) => (
          <li className="shape-item" key={shape.id}>
            <a href="#" onClick={(e) => { e.preventDefault(); handleShapeClick(shape.id); }}>
              <div className="fz-shape-img">
                <img src={`${apiUrl}/uploads/${shape.image || ''}`} alt="image" />
              </div>
              <div className="fz-shape-txt">
                <span>{shape.title || ''}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FrameShapeFilter;
