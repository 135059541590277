import React from 'react'
import SearchFilter from './SearchFilter'
import ProductCategoryList2 from './ProductCategoryList2';
import GenderSelectFilter from './GenderSelectFilter';
import FrameShapeFilter from './FrameShapeFilter';
import FrameBrandFilter from './FrameBrandFilter';
import ProductPriceFilter from './ProductPriceFilter';
import ProductViewFilter from './ProductViewFilter';
import ProductContainer2 from './ProductContainer2';
import ProductPagination from './ProductPagination';

const ShopAreaSection3 = ({ sidebarRef, active }) => {

  return (
    <div className="shop-area">
        <div className="container">
            <div className="row gy-5 justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-9 col-xxs-12 order-1 order-lg-0">
                    <div
        className={`fz-sidebar fz-sidebar-2 ${active ? "active" : ""}`}
        ref={sidebarRef}
      >
                         <SearchFilter/>    
                        <ProductCategoryList2 />  
                        <GenderSelectFilter /> 
                        <FrameShapeFilter />   
                        <FrameBrandFilter />   
                        <ProductPriceFilter/>  
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 order-0 order-lg-1">
                    <ProductViewFilter/>   
                    <ProductContainer2/>   
                    <ProductPagination/> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShopAreaSection3

