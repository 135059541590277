import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
const apiUrl = import.meta.env.VITE_API_URL;
const FooterSection = ({ logo }) => {

  const [footers, setFooters] = useState([]);
  const [socials, setSocials] = useState([]);
  const [pages, setPages] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const [t, i18n] = useTranslation();


  useEffect(() => {
    axios.get(`${apiUrl}/api/categories`)
     .then(response => {
      setCategories(response.data);
      })
     .catch(error => {
        console.error(error);
      });
      axios.get(`${apiUrl}/api/subcategories`)
      .then(response => {
        setSubcategories(response.data);
       })
      .catch(error => {
         console.error(error);
       });
  }, []);
  const getCategorySubcategories = (categoryId) => {
    return subcategories.filter(subcategory => subcategory.category_id === categoryId);
  };
  useEffect(() => {
    axios.get(`${apiUrl}/api/footers`)
     .then(response => {
        setFooters(response.data[0]);
      })
     .catch(error => {
        console.error(error);
      });
  
    axios.get(`${apiUrl}/api/socials`)
     .then(response => {
        setSocials(response.data);
      })
     .catch(error => {
        console.error(error);
      });
      axios.get(`${apiUrl}/api/pages`)
      .then(response => {
        setPages(response.data);
       })
      .catch(error => {
         console.error(error);
       });
       axios.get(`${apiUrl}/api/products`)
       .then(response => {
         setProducts(response.data);
        })
       .catch(error => {
          console.error(error);
        });



  //        axios.get(`${apiUrl}/api/categories`)
  //        .then(response => {
  //           const categoriesData = response.data.reduce((acc, category) => {
  //               acc[category.id] = i18n.language === 'en' ? category.name_en : category.name_ar;
  //             return acc;
  //           }, {});
  //           setCategories(categoriesData);
  //         })
  //        .catch(error => {
  //           console.error(error);
  //         });
  // }, [i18n.language]);
}, [i18n.language]);

  
  const currentYear = new Date().getFullYear();
  return (
    <footer className="fz-footer-section fz-1-footer-section">
      <div className="fz-footer-top">
        <div className="container">
          <div className="row gy-md-5 gy-4 justify-content-center justify-content-lg-between">
            <div className="col-xxl-4 col-lg-12 col-md-8">
              <div className="fz-footer-about fz-footer-widget">
                <div className="fz-logo fz-footer-widget__title">
                  <Link to="/">
                    <img src={`${apiUrl}/uploads/${footers.logo}`} alt="logo" />
                  </Link>
                </div>
                <p className="fz-footer-about__txt">
                {i18n.language === 'en' ? footers.about_en : footers.about_ar}
                </p>


                {footers.payment_method_images && footers.payment_method_images.split(',').map((image, index) => (
  <img key={image} src={`${apiUrl}/uploads/${image.trim()}`} alt="payment method image" width="30" className="fz-payment-methods p-1" />
))}
              
              </div>
            </div>


            <div className="col-xxl-2 col-lg-3 col-md-4 col-6 col-xxs-12">
              <div className="fz-footer-widget">
                <h5 className="fz-footer-widget__title">{t('Customer Service')}</h5>
                <ul>
                {pages.map((page, index) => (
  <li key={page.id}>
    <Link to={`/${page.slug_en}`}>{i18n.language === 'en' ? page.title_en : page.title_ar}</Link>
  </li>
))}
              

                </ul>
              </div>
            </div>

            <div className="col-xxl-2 col-lg-3 col-md-4 col-6 col-xxs-12">
              <div className="fz-footer-widget">
                <h5 className="fz-footer-widget__title">{t('Categories')}</h5>
                <ul>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link to={`/products/category/${category.slug}`}>{category.name_en}</Link>
                  </li>
                ))}
                </ul>
              </div>
            </div>

            <div className="col-xxl-2 col-lg-3 col-md-4 col-6 col-xxs-12">
              <div className="fz-footer__contact-info">
                <h5 className="fz-footer-widget__title">{t('Store Address')}</h5>
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fa-light fa-location-dot"></i>{i18n.language === 'en' ? footers.address_en : footers.address_ar}
                    </Link>
                  </li>
                  <li>
                    <Link to={`tel:${footers.phone}`}>
                      <i className="fa-light fa-phone"></i> {footers.phone}
                    </Link>
                  </li>
                  <li>
                    <Link to={`mailto:${footers.phone}`}>
                      <i className="fa-light fa-envelope-open-text"></i>
                      {footers.email}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fz-footer-bottom">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-md-6 col-12">
              <p className="fz-copyright">
                &copy;
                {footers.copyrights}
              </p>
            </div>

            <div className="col-md-6 col-12">
              <div className="fz-footer-socials">
                <ul>
                {socials.map((social) => (
                  <li key={social.id}>
                    <Link to={social.link}>
                        <img  src=  {`${apiUrl}/uploads/${social.icon}`}
                      />
                    </Link>
                  </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
