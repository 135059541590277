import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../authentication/AuthContext';
import GoogleIcon from '../../../public/assets/images/google-icon.png';
import FacebookIcon from '../../../public/assets/images/facebook-icon.png';
import AppleIcon from '../../../public/assets/images/apple-icon.png';
import axios from 'axios';



const SignInFormSection = () => {
    const { handleLogin } = useContext(AuthContext);
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
    const [loginUrlFacebook, setLoginUrlFacebook] = useState(null);
    const [loginUrlApple, setLoginUrlApple] = useState(null);
    const apiUrl = import.meta.env.VITE_API_URL;


    useEffect(() => { 
            fetch(`${apiUrl}/api/auth/google`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLoginUrlGoogle( data.url ))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/facebook`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLoginUrlFacebook( data.url ))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/apple`, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLoginUrlApple( data.url ))
            .catch((error) => console.error(error));
      }, []);
      








    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!email && !password) {
            toast.error('Please fill out all fields.', { position: 'top-right' });
        } else if (!password) {
            toast.warning('Please provide password.', { position: 'top-right' });
        } else if (!email) {
            toast.warning('Please provide email address.', { position: 'top-right' });
        } else {
            const authenticated = await handleLogin(email, password);
            if (authenticated) {
                toast.success('Signed In successfully!', { position: 'top-right' });
                setEmail('');
                setPassword('');
                navigate('/');
            } else {
                toast.error('Invalid email or password.', { position: 'top-right' });
            }
        }
    };

    return (
        <>
            <form action="#" onSubmit={handleFormSubmit}>
                <input
                    type="email"
                    name="email"
                    id="login-email"
                    placeholder={t('Email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    name="password"
                    id="login-password"
                    placeholder={t('Password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="sign-in-checkbox-container d-flex justify-content-between">
                    <div className="stay-sign-in">
                        <input type="checkbox" name="sign-in-checkbox" id="sign-in-checkbox" />
                        <label htmlFor="sign-in-checkbox">{t('Stay Logged in')}</label>
                    </div>
                    <Link to="/forget-password" className="password-recovery-btn">{t('Forgot Your Password?')}</Link>
                    <Link to="/sign-up" className="password-recovery-btn">{t('Register')}</Link>
                </div>

                <button type="submit" className="fz-1-banner-btn single-form-btn">{t('Log in')}</button>
            </form>
            <hr />
            <p className='text-center'>Or</p>

            <div className="social-icons d-flex justify-content-center m-2">
                <a href={loginUrlGoogle} style={{ marginRight: '10px' }}>
                    <img src={GoogleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                <a href={loginUrlFacebook} style={{ marginRight: '10px' }}>
                    <img src={FacebookIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                {/* <a href={loginUrlApple} style={{ marginRight: '10px' }}>
                    <img src={AppleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a> */}

                
            </div>

        </>
    );
};

export default SignInFormSection;
