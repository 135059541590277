import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FarzaaContext } from '../../context/FarzaaContext';
import { StoreContext } from '../../context/StoreContext';
import { AuthContext } from '../authentication/AuthContext'; 
import TryOn from './TryOn';
const ApiUrl = import.meta.env.VITE_API_URL;

const ProductContainer = ({ shapeId, genderId, brandId }) => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup
    const [pagination, setPagination] = useState({});
    const navigate = useNavigate(); // Initialize navigate
    const { token } = useContext(AuthContext); // Get token from AuthContext
    const { addToWishlist, addToCart } = useContext(StoreContext);


    const handleAddToCart = (product) => {
      if (!token) {
          // If user is not logged in, redirect to login
          navigate('/login');
      } else {
          // If user is logged in, add to cart
          addToCart(product);
      }
  };



    useEffect(() => {
      const fetchProducts = async () => {
        try {
          let apiUrl;

          // Set dynamic API URL based on props
          if (shapeId) {
            apiUrl = `${ApiUrl}/api/products/search?shape_id=${shapeId}`;
          } else if (genderId) {
            apiUrl = `${ApiUrl}/api/products/search?gender_id=${genderId}`;
          } else if (brandId) {
            apiUrl = `${ApiUrl}/api/products/search?brand_id=${brandId}`;
          } else {
            apiUrl = `${ApiUrl}/api/products`;
          }
              
          const response = await axios.get(apiUrl); // Fetch using the dynamic URL
          
          // Check if response contains data
          if (response && response.data) {
            const { data, ...paginationData } = response.data;
            // console.log(response.data);
            console.log(data);
            setProducts(data);
            setFilteredProducts(data);
            setPagination(paginationData);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };

      fetchProducts();
    }, [shapeId, genderId, brandId]);

    const fetchProductsByPage = async (pageNumber) => {
      try {
        let apiUrl = `${ApiUrl}/api/products?page=${pageNumber}`;

        if (shapeId) {
          apiUrl = `${ApiUrl}/api/products/search?shape_id=${shapeId}&page=${pageNumber}`;
        } else if (genderId) {
          apiUrl = `${ApiUrl}/api/products/search?gender_id=${genderId}&page=${pageNumber}`;
        } else if (brandId) {
          apiUrl = `${ApiUrl}/api/products/search?brand_id=${brandId}&page=${pageNumber}`;
        }

        const response = await axios.get(apiUrl);
        
        if (response && response.data) {
          const { data, ...paginationData } = response.data;
          setProducts(data);  // Set only data, not the entire response object
          setFilteredProducts(data);
          setPagination(paginationData);
        }
      } catch (error) {
        console.error("Error fetching paginated products:", error);
      }
    };

    const { isListView } = useContext(FarzaaContext);
    // const { addToWishlist, addToCart } = useContext(StoreContext);

    // Function to open the popup  
    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    return (
      <div className={`fz-inner-products-container ${isListView ? 'list-view-on' : ''}`}>
      <div className="row justify-content-center">
        {filteredProducts.length === 0 ? (
          <div className='no-product-area'>
            <h3 className='no-product-text'>{t('No Products Available')}</h3>
            <p className='no-product-desc'>{t("We're sorry. We cannot find any matches for your search term.")}</p>
          </div>
        ) : (
          filteredProducts.map((product) => (
            <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
              <div className="fz-5-single-product">
                <div className="fz-5-single-product-img">
                  <img src={`${ApiUrl}/uploads/${product.image}`} alt={product.title_en} />
                  <div className="fz-5-single-product-actions">
                    <a 
                      role="button" 
                      className="fz-add-to-wishlist"
                      onClick={() => addToWishlist(product)}
                    >
                      <i className="fa-regular fa-heart"></i>
                    </a>
                    <a
                      role="button"
                      className="fz-add-to-cart-btn" 
                      onClick={() => handleAddToCart(product)} // Use the new function here
                    >
                      <i className="fa-regular fa-cart-shopping"></i>
                    </a>                  
                  </div>
                </div>
                <div className="fz-5-single-product-txt">
                  <h3 className="fz-5-single-product-title">
                    <Link to={`/product/${product.slug_en}`}>
                      {i18n.language === 'en' ? product.title_en : product.title_ar}
                    </Link>
                  </h3>
                  <p className="fz-5-single-product-price">{t("SR")} {product.selling_price}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
            
                      {/* <a
                        role="button"
                        className="fz-add-to-cart-btn" 
                        onClick={openPopup}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-eyeglasses" viewBox="0 0 16 19">
                          <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                        </svg>
                      </a> */}


        {/* Pagination */}
        <div className="p-2">
          {pagination.links && (
            <nav aria-label="Pagination">
              <ul className="pagination justify-content-center">
                {pagination.links.map((link, index) => (
                  <li key={index} className={link.active ? 'page-item active' : 'page-item'}>
                    <a
                      style={{ color: 'black' }}
                      className="page-link"
                      href={link.url}
                      onClick={(e) => {
                        e.preventDefault();
                        const pageNumber = link.label === 'Next' ? pagination.current_page + 1 : link.label === 'Previous' ? pagination.current_page - 1 : link.label;
                        fetchProductsByPage(pageNumber);
                      }}
                    >
                      {link.label === '&laquo; Previous' ? t('Previous') : link.label === 'Next &raquo;' ? t('Next') : link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>

        {/* Try-On Popup */}
        {isPopupOpen && (
          <div className="try-on-popup">
            <div className="try-on-content">
              <button className="close-popup" onClick={closePopup}>Close</button>
              <TryOn />
            </div>
          </div>
        )}
      </div>
    );
};

export default ProductContainer;
