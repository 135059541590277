import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { StoreContext } from '../../context/StoreContext';
const apiUrl = import.meta.env.VITE_API_URL;
const CartItemTable = () => {
 
  const { t, i18n }= useTranslation();
  const { cart, 
  removeFromCart, 
  products,
   updateCartQuantity
} = useContext(StoreContext);

  return (
    <table className="cart-page-table">
      <tbody>
        <tr>
        <th>{t('Product')}</th>
        <th>{t('Price')}</th>
          <th>{t('Quantity')}</th>
          <th>{t('Total')}</th>
          <th>{t('Remove')}</th>
        </tr>
        {cart.length === 0 ? (
          <tr className="no-item-msg">
            <td className="no-item-msg-text">{t('No items in the cart')}</td>
          </tr>
        ) : (
          cart.map((product) => {
            const productDetails = products[product.product_id];
            if (!productDetails) {
              return null; // or return a loading indicator, etc.
            }
            return (
            <tr key={product.id}>
              <td>
                <div className="cart-product">
                  <div className="cart-product__img">
                    <img src={`${apiUrl}/uploads/${productDetails.image}`} alt="Product Image" />
                  </div>
                  <div className="cart-product__txt">
                    <h6>
                      <Link to={`/product/${productDetails.slug_en}`}>{i18n.language === 'en'? productDetails.title_en : productDetails.title_ar}</Link>
                    </h6>
                  </div>
                </div>
              </td>
              <td>{t('SR')} {productDetails.selling_price}</td>
              <td>
  <div className="cart-product__quantity">
    <div className="cart-product__quantity-btns">
      <button
        className="cart-product__minus"
        onClick={() => updateCartQuantity(product.product_id, product.quantity - 1)}
      >
        <i className="fa-light fa-minus"></i>
      </button>
      <button
        className="cart-product__plus"
        onClick={() => updateCartQuantity(product.product_id, product.quantity + 1)}
      >
        <i className="fa-light fa-plus"></i>
      </button>
    </div>
    <input
      type="number"
      name="product-quantity-input"
      className="cart-product-quantity-input"
      min="0"
      value={product.quantity}
      onChange={(event) => {
        const newQuantity = parseInt(event.target.value);
        updateCartQuantity(product.id, newQuantity);
      }}
    />
  </div>
</td>

              <td>{t('SR')} {productDetails.total ? productDetails.total : product.quantity * productDetails.selling_price}</td>
              <td>
                   <button
                      className="item-remove-btn"
                      onClick={() => removeFromCart(product)}
                    >
                  <i className="fa-light fa-xmark"></i>
                </button>
              </td>
            </tr>
            
              );
            })
          )}
          
                </tbody>
    </table>
  );
};

export default CartItemTable;
