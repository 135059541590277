import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../components/authentication/AuthContext';
import { toast } from "react-toastify";
const apiUrl = import.meta.env.VITE_API_URL;

export const StoreContext = createContext();


const StoreContextProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
   const { token } = useContext(AuthContext);
   const [products, setProducts] = useState({});
    const [cart, setCart] = useState([]);
    const [tryOn, setTryOn] = useState({}); 
    const [pagination, setPagination] = useState({
      currentPage: 1,
      totalPages: 1,
      productsPerPage: 15,
    });

   const [cartItems, setCartItems] = useState({}); // Initialize as an object



  // Wishlist items


   useEffect(() => {
    if (token) {
      axios.get(`${apiUrl}/api/wishlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setWishlist(response.data);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [token]);


  const fetchProductDetails = async (wishlistItems) => {
    try {
      const promises = wishlistItems.map((item) => {
        return axios.get(`${apiUrl}/api/product-${item.product_id}`);
      });
      const responses = await Promise.all(promises);
      const productsData = responses.map((response) => response.data);
      const productsMap = productsData.reduce((acc, product) => ({ ...acc, [product.id]: product }), {});
      setProducts(productsMap);
    } catch (error) {
      console.error("Error fetching product details", error);
    }
  };



    useEffect(() => {
    fetchProductDetails(wishlist);
  }, [wishlist]);


  const addToWishlist = async (product) => {
    try {
      const response = await axios.post(`${apiUrl}/api/add-wishlist`, {
        user_id: token.user_id,
        product_id: product.id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        setWishlist([...wishlist, product]);
        toast.success("Item added to wishlist!");
      } else {
        toast.error("Failed to add item to wishlist!");
      }
    } catch (error) {
      toast.error("Error adding item to wishlist!");
    }
  };

  const removeFromWishlist = async (product) => {
    try {
      const response = await axios.post(`${apiUrl}/api/delete-wishlist/${product.id}`, {
        user_id: token.user_id,
        product_id: product.id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        setWishlist(wishlist.filter((wishlistProduct) => wishlistProduct.id !== product.id));
  
        toast.success("Item removed from wishlist!");
      } else {
        toast.error("Failed to remove item from wishlist!");
      }
    } catch (error) {
      toast.error("Error removing item from wishlist!");
    }
  };



  const addToCartFromWishlist = async (product) => {
    try {
      const response = await axios.post(`${apiUrl}/api/wishlistToCart`, {
        user_id: token.user_id,
        product_id: product.product_id, // Use product.product_id instead of product.id
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 201) {
        setWishlist(wishlist.filter((wishlistProduct) => wishlistProduct.product_id !== product.product_id));
        toast.success("Product added to cart from wishlist!");
      } else {
        toast.error("Failed to add item to cart from wishlist!");
      }
    } catch (error) {
      toast.error("Error adding item to cart from wishlist!");
    }
  };


  // carts items

  const getCartQuantity = () => {
    return cart.reduce((acc, item) => acc + item.quantity, 0);
  };

  useEffect(() => {
    if (token) {
      axios.get(`${apiUrl}/api/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setCart(response.data);
          const cartItemsMap = response.data.reduce((acc, item) => ({...acc, [item.product_id]: item }), {});
          setCartItems(cartItemsMap);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios.get(`${apiUrl}/api/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then(response => {
        const cartItems = response.data.map((item) => ({...item, quantity: item.quantity || 1 }));
  setCart(cartItems);
        const cartItemsMap = cartItems.reduce((acc, item) => ({...acc, [item.product_id]: item }), {});
        setCartItems(cartItemsMap);
      })
    .catch(error => {
        console.error(error);
      });
    }
  }, [token]);

  const fetchCartProductDetails = async () => {
    try {
      const promises = Object.keys(cartItems).map((productId) => {
        return axios.get(`${apiUrl}/api/product-${productId}`);
      });
      const responses = await Promise.all(promises);
      const productsData = responses.map((response) => response.data);
      const productsMap = productsData.reduce((acc, product) => ({...acc, [product.id]: product }), {});
      setProducts(productsMap);
    } catch (error) {
      console.error("Error fetching cart product details", error);
    }
  };
 

  useEffect(() => {
    fetchCartProductDetails(cart);
  }, [cart]);


  


  const addToCart = async (product) => {
    try {
      const response = await axios.post(`${apiUrl}/api/add-cart`, {
        user_id: token.user_id,
        product_id: product.id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        const newCartItem = { ...product, quantity: 1 }; // Set quantity to 1
        setCart([...cart, newCartItem]);
        const updatedCartItems = { ...cartItems, [product.id]: newCartItem };
        setCartItems(updatedCartItems);
        toast.success("Item added to Cart!");
      } else {
        toast.error("Failed to add item to Cart!");
      }
    } catch (error) {
      toast.error("Error adding item to Cart!");
    }
  };

  const updateCartQuantity = async (productId, newQuantity) => {
    try {
      const response = await axios.post(`${apiUrl}/api/update-cart-quantity/${productId}`, {
        user_id: token.user_id,
        product_id: productId,
        quantity: newQuantity,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        const updatedCart = cart.map((item) => {
          if (item.product_id === productId) {
            return { ...item, quantity: newQuantity };
          }
          return item;
        });
        setCart(updatedCart); // Update the cart state using setCart
        toast.success("Quantity updated!");
      } else {
        toast.error("Failed to update quantity!");
      }
    } catch (error) {
      toast.error("Error updating quantity!");
    }
  };

  const removeFromCart = async (product) => {
    try {
      // Make sure the product object has an id
      if (!product || !product.id) {
        throw new Error("Product ID is required");
      }
  
      const response = await axios.post(`${apiUrl}/api/delete-cart/${product.id}`, {
        user_id: token.user_id, // Ensure user_id is being sent correctly
        product_id: product.id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        // Update the cart state to remove the product
        setCart((prevCart) => prevCart.filter((cartProduct) => cartProduct.id !== product.id));
        toast.success("Item removed from Cart!");
      } else {
        toast.error("Failed to remove item from Cart!");
      }
    } catch (error) {
      console.error("Error removing item from Cart:", error);
      toast.error("Error removing item from Cart!");
    }
  };
  // const removeFromCart = async (product) => {
  //   try {
  //       const response = await axios.post(`${apiUrl}/api/delete-cart/${product.id}`, {

  //       user_id: token.user_id,
  //       product_id: product.id,
  //     }, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  
  //     if (response.status === 200) {
  //       setCart(cart.filter((cartProduct) => cartProduct.id !== product.id));
  
  //       toast.success("Item removed from Cart!");
  //     } else {
  //       toast.error("Failed to remove item from Cart!");
  //     }
  //   } catch (error) {
  //     toast.error("Error removing item from Cart!");
  //   }
  // };


  return (
    <StoreContext.Provider value={{ wishlist,
     addToWishlist, 
     removeFromWishlist,
     products,
     addToCartFromWishlist,
     cart, 
     addToCart, 
     pagination,
     removeFromCart, 
     updateCartQuantity,
     getCartQuantity,
     tryOn,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;