import React, { useState, useEffect, useContext } from "react";
import BlogContainerSidebar from './BlogContainerSidebar';
import { FarzaaContext } from '../../context/FarzaaContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const apiUrl = import.meta.env.VITE_API_URL;


const BlogContainer = () => {
    const {t, i18n} = useTranslation();

    const keywords = {
        en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
        ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
      };
      const titles = {
        en: "Regalesyes | Blogs",
        ar: "ريجل ايز | المدونة"
      };
      const desriptions = {
        en: "Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort.",
        ar: "اكتشف Regaleyes، وجهتك الأولى للحصول على منتجات بصرية عالية الجودة. استكشف مجموعتنا الواسعة من العدسات والنظارات والإكسسوارات المصممة للأناقة والراحة."
      };
    
    
      const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
      const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
      const currentDescription = i18n.language === 'ar' ? desriptions.ar : desriptions.en;


    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState({}); // new state to store category data

    useEffect(() => {
        axios.get(`${apiUrl}/api/blogs`)
         .then(response => {
            const blogsData = response.data.map(blog => ({
              id: blog.id,
              slug_en: blog.slug_en,
              slug_ar: blog.slug_ar,
              title_en: blog.title_en,
              title_ar: blog.title_ar,
              imgSrc: blog.image,
              desc_en: blog.description_en,
              desc_ar: blog.description_ar,
              categoryBlog_id: blog.categoryBlog_id,
              date: blog.created_at 
            }));
            setBlogs(blogsData);
          })
         .catch(error => {
            console.error(error);
          });
    
        // fetch category data
        axios.get(`${apiUrl}/api/categoryBlogs`)
         .then(response => {
            const categoriesData = response.data.reduce((acc, category) => {
                acc[category.id] = i18n.language === 'en' ? category.name_en : category.name_ar;
              return acc;
            }, {});
            setCategories(categoriesData);
          })
         .catch(error => {
            console.error(error);
          });
      }, []);

    

    const {paginatedBlogPost,currentBlogPage,handleBlogPageChange,totalBlogPage} = useContext(FarzaaContext)

    return (
        <>
         
    <Helmet>
  <title>{currentTitles}</title>
  <meta name="description" content={currentDescription} />
  <meta name="keywords" content={currentKeywords} />
  </Helmet>
       
        <div className="container">
            
            <main className="blog-page-content">
                <div className="blogs-container">
                    <div className="blogs">
                        {blogs.length === 0 ? (
                            <div className='no-blog-post-area'>
                                <h3 className='no-blog-post-text'>{t('No Blog Post Available')}</h3>
                                <p className='no-blog-post-desc'>{t('There are no blog post according to your search tags')}</p>
                            </div>
                        ):(
                            blogs.map((blog) => (
                            <div className="fz-single-blog fz-inner-page-blog" key={blog.id}>
                                <div className="fz-single-blog__img">
                                    <img src={`${apiUrl}/uploads/${blog.imgSrc}`} alt={blog.title_en}/>
                                </div>
                                <div className="fz-single-blog__txt">
                                    <div className="fz-single-blog__infos">
                                        <span className="fz-single-blog__category"><Link to="#">{categories[blog.categoryBlog_id]}</Link></span>
                                        
                                        <span className="fz-single-blog__date">{moment(blog.date).format('MM/DD/YYYY')}</span>

                                        {/* <span className="fz-single-blog__date">{blog.date}</span> */}
                                    </div>

                                    <h3 className="fz-single-blog__title">
                                        <Link to={`/blogDetails/${blog.slug_en}`}>{i18n.language === 'en' ? blog.title_en : blog.title_ar}</Link>
                                    </h3>
                                    <p className="fz-single-blog__desc">
                                    {i18n.language === 'en' ? blog.desc_en.split(' ').slice(0, 30).join(' ') : blog.desc_ar.split(' ').slice(0, 30).join(' ')}                                    </p>
                                    <Link to={`/blogDetails/${blog.slug_en}`} className="fz-1-banner-btn fz-single-blog__btn">{t('Read More')}</Link>
                                </div>
                            </div> 
                        ))
                        )}
                    </div>
                        <nav className="fz-shop-pagination">
                            <ul className="page-numbers">
                                    <li>
                                        <button
                                            disabled={currentBlogPage === 1}
                                            onClick={() => handleBlogPageChange(currentBlogPage - 1)}
                                            className='page-number-btn'
                                        >
                                            <span aria-current="page" className="last-page"><i className="fa-light fa-angle-double-left"> </i></span>
                                        </button>
                                    </li>
                                {Array.from({ length: Math.ceil(totalBlogPage) }).map((_, index) => (
                                    <li key={index}>
                                        <button
                                            className={`page-number-btn ${currentBlogPage === index + 1 ? 'current' : ''}`}
                                            onClick={() => handleBlogPageChange(index + 1)}
                                        >
                                            <span aria-current="page" className="page-number">
                                                {index + 1}
                                            </span>
                                        </button>
                                    </li>
                                ))}
                                    <li>
                                        <button
                                            disabled={currentBlogPage === totalBlogPage}
                                            className='page-number-btn'
                                            onClick={() => handleBlogPageChange(currentBlogPage + 1)}
                                        >
                                        <span aria-current="page" className="last-page"><i className="fa-light fa-angle-double-right"> </i></span>
                                        </button>
                                    </li>
                            </ul>
                        </nav>
                    </div>

                    <BlogContainerSidebar />
                
            </main>
        </div>
        </>
    );
};

export default BlogContainer;
