import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const OfferSection4 = () => {
  const { t } = useTranslation();

  return (
    <section className="fz-5-offer-section">
      <div className="container">
        <div className="row g-4">
          <div className="col-md-6">
            <div className="fz-5-single-offer">
              <div className="fz-5-single-offer-img">
                <img src="assets/images/offer-bg-5.png" alt="Image" />
              </div>
              <div className="fz-5-single-offer-txt">
                <h4 className="fz-5-single-offer__title">{t('Sunglasses')}</h4>
                <Link to="/products/category/sunglasses" className="fz-5-def-btn fz-5-def-btn-sm">
                {t('shop_now')}
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="fz-5-single-offer">
              <div className="fz-5-single-offer-img">
                <img src="assets/images/offer-bg-6.png" alt="Image" />
              </div>
              <div className="fz-5-single-offer-txt">
                <h4 className="fz-5-single-offer__title">{t('Eye Glasses')}</h4>
                <Link to="/products/category/eyeglasses" className="fz-5-def-btn fz-5-def-btn-sm">
                {t('shop_now')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferSection4;
