import { useContext,useState,useEffect } from 'react';
import { StoreContext } from '../../context/StoreContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;
 
const WishlistItemTable = () => {
  const [t, i18n] = useTranslation();
  const { wishlist, 
          removeFromWishlist, 
          products,
          addToCartFromWishlist
     } = useContext(StoreContext);
        

  return (
    <div className='wishlist-table'>
      <table>
        <tbody>
          <tr>
            <th>{t('Product')}</th>
            <th>{t('Price')}</th>
            <th>{t('Action')}</th>
            <th>{t('Remove')}</th>
          </tr>
          {wishlist.length === 0? (
            <tr className='no-item-msg'>
              <td className='no-item-msg-text'>{t('No items in the wishlist.')}</td>
            </tr>
          ) : (
            wishlist.map((product) => {
              const productDetails = products[product.product_id];
              if (!productDetails) {
                return null; // or return a loading indicator, etc.
              }
              return (
                <tr key={product.id}>
                  <td>
                    <div className="cart-product">
                      <div className="cart-product__img">
                        <img src={`${apiUrl}/uploads/${productDetails.image}`} alt="Product Image"/>
                      </div>
                      <div className="cart-product__txt">
                        <h6><Link to={`/product/${productDetails.slug_en}`}>{i18n.language === 'en'? productDetails.title_en : productDetails.title_ar}</Link></h6>
                      </div>
                    </div>
                  </td>
                  <td>{t('SR')} {productDetails.selling_price}</td>
                  <td>
                    <div className="fz-wishlist-action">
                      <button 
                        className="fz-add-to-cart-btn fz-1-banner-btn fz-wishlist-action-btn"
                        onClick={() => addToCartFromWishlist({ product_id: product.product_id })}
                      >{t('Add to cart')}</button>
                    </div>
                  </td>
                  <td>
                    <button
                      className="item-remove-btn"
                      onClick={() => removeFromWishlist(product)}
                    >
                      <i className="fa-light fa-xmark"></i>
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table> 
    </div>
  );
};

export default WishlistItemTable;










